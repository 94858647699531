import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { FallingLines } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import logo from '../../assets/images/logo.png';

import CryptoJS from "crypto-js";
import { authHeader } from "../../utils";
import "./index.css";

const EventPlay = () => {
  const navigate = useNavigate();
  const sectionEvPlayRef = useRef(null);
  const [event, setEvent] = useState({});
  const [questions, setQuestions] = useState([]);
  const [index, setIndex] = useState(0);
  const { eventId } = useParams();
  const [counter, setCounter] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [optionColor, setOptionColor] = useState("");

  useEffect(() => {
    if (!isSubmitting) {
      const timer = setTimeout(() => {
        if (parseFloat(counter) <= 0.1) {
          chooseAnswer();
          return;
        }
        setCounter(counter - 0.1);
      }, 100);
      return () => clearTimeout(timer);
    }
  });

  useEffect(() => {
    setIsSubmitting(true);
    const url = "https://quizbuz-backend.techknowgram.net/api/react/events";

    axios
      .get(url + "/" + eventId, { headers: authHeader() })
      .then(function (response) {
        const { data } = response;
        let encrypted = data.encrypted;
        let key = "P53Eryk3cPcED2pKV2FBkvzOIuFty63djJKQSEa6bEs";

        encrypted = JSON.parse(atob(encrypted));
        //console.log(encrypted);

        const iv = CryptoJS.enc.Base64.parse(encrypted.iv);
        const value = encrypted.value;

        key = CryptoJS.enc.Base64.parse(key);

        let decrypted = CryptoJS.AES.decrypt(value, key, { iv: iv }).toString(
          CryptoJS.enc.Utf8
        );

        const myData=JSON.parse(decrypted)
        setEvent(myData);
        setCounter(myData.event_mark_setup.per_mcq_time_duration);
        const shuffledQuestions = shuffleArray(myData.questions);
        setQuestions(shuffledQuestions);
        setIsSubmitting(false);
      })
      .catch(function (error) {});
  }, []);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const chooseAnswer = (e) => {
    setOptionColor(getRandomColor());
    // e.target.data('answer');
    const answerChosen = e ? e.target.getAttribute("data-answer") : null;

    const answerObj = {
      question_id: questions[index].id,
      answer: answerChosen,
      time:
        event.event_mark_setup.per_mcq_time_duration - counter == 0
          ? 1
          : event.event_mark_setup.per_mcq_time_duration - counter,
    };

    setAnswers([...answers, answerObj]);

    if (!isSubmitting && index == event.event_mark_setup.total_mcq - 1) {
      const lastAnswer = answerObj;
      const postObj = {
        event_id: event.id,
        answers: [...answers, lastAnswer],
      };

      setIsSubmitting(true);

      const url =
        "https://quizbuz-backend.techknowgram.net/api/react/submitAnswers";

      axios
        .post(url, postObj, { headers: authHeader() })
        .then(function (response) {
          const { data } = response;

          setIsSubmitting(false);

          //navigate to result page here
          navigate("/myResult", {
            state: data,
          });
        })
        .catch(function (error) {});
    } else {
      if (!isSubmitting) {
        setIndex(index + 1);
        setCounter(event.event_mark_setup.per_mcq_time_duration);
      }
    }
  };

  let options = [];
  if (questions.length) {
    for (const key in questions[index].options) {
      if (Object.hasOwnProperty.call(questions[index].options, key)) {
        const element = questions[index].options[key];

        const obj = {
          option_key: key,
          option_value: element,
        };

        options.push(obj);
      }
    }
  }

  function getRandomColor() {
    var r = Math.floor(Math.random() * 256);
    var g = Math.floor(Math.random() * 256);
    var b = Math.floor(Math.random() * 256);
    var l = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    if (l < 160) {
      return getRandomColor();
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  }

  useEffect(() => {
    sectionEvPlayRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <>
      <section id="banner" class="Banner_others section_gaps faq">
        <div class="container">
          <div class="row d_flex">
            <div class="col-lg-6 m-auto">
              <div class="left">
                <h1>কুইজে অংশগ্রহন করুন</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section style={{ height: "50px" }} ref={sectionEvPlayRef}></section>
      <section id="attend_quiz" class="section_gaps">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6 col-12 m-auto">
              <div class="attend_quiz_item">
                <div class="img">
                  <img
                    class="img-fluid w-sm-50 w-md-100"
                    src={logo}
                    alt=""
                  />
                </div>
                <div class=" start_quiz">
                  {/* <span>L- {event.event_name && event.event_mark_setup.total_level}</span> */}
                  {event.event_name ? (
                    <div class="time">
                      <>
                        <h6 id="count">
                          {" "}
                          {Math.floor(counter)}:
                          {Math.round((counter - Math.floor(counter)) * 100)}
                        </h6>{" "}
                        <span> sec</span>
                      </>
                    </div>
                  ) : null}

                  {questions.length ? (
                    <h4 className="copyRestrict">
                      {" "}
                      {questions[index].question}{" "}
                    </h4>
                  ) : null}

                  <div class="text">
                    <ul>
                      {options.length ? (
                        options.map((option, i) => (
                          <li style={{ backgroundColor: optionColor }}>
                            <a
                              onClick={chooseAnswer}
                              class="option copyRestrict"
                              data-answer={option.option_key}
                              style={{ backgroundColor: optionColor }}
                            >
                              {i == 0 && <span>ক</span>}
                              {i == 1 && <span>খ</span>}
                              {i == 2 && <span>গ</span>}
                              {option.option_value}
                            </a>
                          </li>
                        ))
                      ) : (
                        <FallingLines
                          color="#4fa94d"
                          width="100"
                          visible={true}
                          ariaLabel="falling-lines-loading"
                        />
                      )}
                    </ul>

                    {/* <!-- Prograssbar --> */}
                    <div class="quiz_prograss d_flex">
                      <div class="progress">
                        <div
                          class="progress-bar"
                          role="progressbar"
                          style={{ width: "10%" }}
                          aria-valuenow="10"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>

                      <h5>
                        <span id="totalcount"> {index + 1} </span> /{" "}
                        <span id="questionperlavel">
                          {" "}
                          {event.event_name &&
                            event.event_mark_setup.question_per_level}
                        </span>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EventPlay;
