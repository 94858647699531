import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import iso1 from '../../../assets/images/iso1.png';
import iso2 from '../../../assets/images/iso2.png';
import iso3 from '../../../assets/images/iso3.jpg';
import logo from '../../../assets/images/logo.png';
import "./index.css";

const Footer = () => {

  const navigate = useNavigate();

  const [about, setAbout] = useState(false);

  const goToRule = (e) => {
    e.preventDefault();
    navigate('/rule');
  }

  const goToEvents = (e) => {
    e.preventDefault();
    navigate('/events');
  }

  const goToFaq = (e) => {
    e.preventDefault();
    navigate('/faq');
  }

  const goToabout = (e) => {
    setAbout(!about);
  }

  return (
    <footer id="footer" className="section_gaps home_footer">
      <div className="container none-on-small">
        <div className="row">
          <div className="col-lg-4 col-md-3 px-5">
            <h3 className="about">About us</h3>
            <h5 style={{textAlign: "justify"}} >QuizBuz is a knowledge based gaming platform of TechKnowGram Limited. TechKnowGram Limited is a leading technology company based in Bangladesh that specializes in providing web-based customized solutions in various areas, including ERP, AI, Data Analytics, Robotics, Embedded Solutions with Beacon, AR, VR Applications, Gaming, and Animation. {!about && ("...")}{about && (<h5>The company is globally recognized for its expertise in the Japan ICT market, where it has been operating for over 20 years. Additionally, TechKnowGram Limited also has a strong presence in the USA and Europe markets. With over 25 years of combined professional experience, the company's team of experts is dedicated to delivering innovative solutions to its clients. TechKnowGram Limited is proud to be an ISO 9001: 2015, ISO 14001: 2015, and ISO/IEC 27001: 2013 certified company and a member of several reputable organizations, including Bangladesh Association of Software and Information Services BASIS, Bangladesh and Japan Chamber of Commerce & Industries JBCCI, Bangladesh Computer Samity BCS, Bangladesh Association of Call Centers and Outsourcing BACCO, Ecommerce Association of Bangladesh e-CAB, CTO Forum of Bangladesh, and SheTrades Commonwealth Program by International Trade Centre ITC.</h5>)}  </h5><h5 onClick={goToabout} style={{ color: "blue", cursor: "pointer" }}>{about ? "Read less" : "Read more"}</h5>

            <div className="social d-flex align-items-center">
              <img height={50} width={50} src={iso1} alt="" />
              <img className="mx-3 mt-2" height={55} width={70} src={iso2} alt="" />
              <img height={50} width={50} src={iso3} alt="" />
            </div>
          </div>
          <div className="col-lg-3 col-md-3">
            <div className="footer_content">

              <h3>Contact</h3>

              <div className="list-none">

                <h4><a style={{ color: "#9700ff" }} href="https://www.techknowgram.com" target={"_blank"} rel="noreferrer">TechKnowGram Limited</a></h4>

                <h5><a>5/9 Block B, Suite B1, Lalmatia, Dhaka 1207, Bangladesh.</a></h5>

                <h5><a href="">info@quizbuz.xyz</a></h5>

                <h5><a href="">01819250309</a></h5>

              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-3 aboutpage">
            <div className="footer_content">

              <h3>Pages</h3>

              <div className="list-none">

                <h5><a onClick={goToRule} href="">নিয়মাবলি</a></h5>

                <h5><a onClick={goToEvents} href="">সকল কুইজ</a></h5>

                <h5><a onClick={goToFaq} href="">FAQ</a></h5>

              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-3">
            <div className="logo">
              <img className="img-fluid" width={"70%"} src={logo} alt="" />
            </div>
            <h5 className="mb-2">Follow Us On:</h5>

            <div className="social mb-5">
              <a href="https://www.facebook.com/profile.php?id=100088235866815" target={"_blank"} rel="noreferrer">
                <i className="fab fa-facebook-square" style={{ fontSize: "30px", color: "#1877F2", marginRight: "10px" }} ></i>
              </a>
              <a href="https://twitter.com/Quizwin_xyz" target={"_blank"} rel="noreferrer" >
                <i className="fab fa-twitter" style={{ fontSize: "30px", color: "#1D9BF0", marginRight: "10px" }}></i>
              </a>
              <a href="https://www.instagram.com/quizwin.xyz/" target={"_blank"} rel="noreferrer">
                <i className="fab fa-instagram" style={{ fontSize: "30px", color: "#FF0000", marginRight: "10px" }}></i>
              </a>
              <a href="https://www.linkedin.com/in/quizwin-xyz-b54152266/" target="_blank" rel="noreferrer">
                <i className="fab fa-linkedin" style={{ fontSize: "30px", color: "#0A66C2", marginRight: "10px" }}></i>
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div id="footer-bottom" className="clr no-footer-nav p-5">
            <div id="footer-bottom-inner" className="container clr">
              <div id="copyright" className="clr" role="contentinfo">
                <div className="d-flex justify-content-center align-items-center"> © All Rights Reserved <a href="https://techknowgram.com" target="_Blank" rel="noreferrer">  </a>

                  <a href="#"></a> &nbsp;& Powered By TechKnowGram Limited<a href="https://www.techknowgram.com" target="_Blank" rel="noreferrer"> <img src={require("../../../images/tkgl_logo.png")} width="100" height="35" className="lazyloaded" data-ll-status="loaded" /><noscript><img src={require("../../../images/tkgl_logo.png")} width="100" height="150" /></noscript> </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
