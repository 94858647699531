import React from 'react';
import { useNavigate } from 'react-router-dom';
import './index.css';

const PaymentSuccess = () => {
    const navigate= useNavigate();

    const goHome=(e)=> {
        e.preventDefault();
        navigate("/");

    }
    return (
        <section id="login">

            <div class="container">

                <div class="row">

                    <div class="col-lg-6 col-md-8 col-sm-8 m-auto">

                        <div class="login_coltent">

                            <div class="img">
                                <img src="https://quizbuz.techknowgram.com/static/media/logo.905c041105092862de9d.png" alt="" />
                            </div>

                            <h2>Point Purchase Successful</h2>


                            <div class="custome_input">
                                <a onClick={goHome} href="/"><button type="button" class="resend"> PLay
                                    Now </button></a>

                            </div>

                        </div>



                    </div>

                </div>

            </div>

        </section>
    )
}

export default PaymentSuccess