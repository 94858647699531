/**
 * Returns a gretting message based on time
 * 
 * @returns {string}
 */
export function greetingMessage() {
  const hour = new Date().getHours();

  return `Good ${
    hour <= 4
      ? "Late Night"
      : hour <= 11
      ? "Morning"
      : hour <= 15
      ? "Noon"
      : hour <= 18
      ? "Afternoon"
      : hour <= 20
      ? "Evening"
      : "Night"
  }`;
}
