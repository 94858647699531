import axios from "axios";
import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import WelcomeModal from "./WelcomeModal";

import CookieConsent from "react-cookie-consent";
import { connect } from "react-redux";
import EmailVerificationAlert from "../EmailVerificationAlert";

const Layout = ({
  children,
  changeLn,
  t,
  login,
  setlogin,
  isAuthenticated,
  userInfo,
}) => {
  const [show, setShow] = useState(false);
  const [weatherData, setWeatherData] = useState({}); //State for weather data
  const [locationData, setLocationData] = useState({}); //State for latitude and longitude data
  const appKey = "7806a709857cc30443bdb46ff3594cc9";

  const handleClose = () => {
    localStorage.setItem("userWelcomed", true);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  useEffect(() => { }, [login]);

  useEffect(() => {
    const userWelcomed = localStorage.getItem("userWelcomed");
    if (!userWelcomed) {
      handleShow();
    }
  });

  /**
   * For getting user location for weather information
   */
  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLocationData({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    });
  }, []);

  /**
   * For fetching weather information
   */
  useEffect(() => {
    const { latitude, longitude } = locationData;

    if (JSON.stringify(locationData) != "{}") {
      axios
        .get(
          `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${appKey}`
        )
        .then((response) => {
          setWeatherData(response.data);
        });
    }
  }, [locationData, appKey]);

  console.log(userInfo);
  return (
    <>

      <Header />

      {children}

      <Footer />

      {isAuthenticated ? (
        <>{userInfo.email_verified_at ? "" : <EmailVerificationAlert />}</>
      ) : (
        ""
      )}

      <CookieConsent
        location="bottom"
        buttonText="Acccept"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
        onAccept={(acceptedByScrolling) => {
        }}
        enableDeclineButton
        onDecline={() => {
          // alert("nay!");
        }}
      >
        This website uses cookies to enhance the user experience.{" "}

      </CookieConsent>
      {isAuthenticated && (
        <WelcomeModal
          show={show}
          handleClose={handleClose}
          userInfo={userInfo}
          weatherData={JSON.stringify(weatherData)}
        />
      )}

      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">ADDRESS</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <ul>
                <li> TechKnowGram Limited:</li>
                <li> 5/9 Block B, Suite B1 Lalmatia Dhaka 1207. </li>
                <li> Info@TechKnowGram.com. </li>
                <li> Cell: +8801819250309 </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.auth.user,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
