import React from 'react';
import { useNavigate } from 'react-router-dom';
import "./index.css";

const EmailVerificationAlert = () => {

    const navigate= useNavigate();

const goToProfile=(e)=> {
    navigate("/profile")
}
  return (
    <div onClick={goToProfile} className='emailVerificationAlert'>Please verify your email address. Otherwise you won't be able to change your password</div>
  )
}

export default EmailVerificationAlert