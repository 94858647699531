import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import './index.css';

const MyResult = () => {
    const { state } = useLocation();
    const sectionEvResult = useRef(null);
    useEffect(() => {
        sectionEvResult.current.scrollIntoView({ behavior: 'smooth' });
    }, []);
    return (
        <>
            <section id="banner" class="Banner_others section_gaps faq">
                <div class="container">
                    <div class="row d_flex">
                        <div class="col-lg-6 m-auto">
                            <div class="left">
                                <h1>কুইজে অংশগ্রহন করুন</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section style={{ height: "50px" }} ref={sectionEvResult}></section>
            <section id="attend_quiz" class="section_gaps">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-sm-6 col-md-6 col-12 m-auto">
                            <div class="attend_quiz_item">
                                <div class="img">
                                    <img class="img-fluid w-sm-50 w-md-100" src={logo} alt="" />
                                </div>
                                <div class="text">
                                    <h3> Full Overview </h3>

                                    <br />
                                    <br />

                                    <p>ইভেন্ট - {state.result.event_name}</p>
                                    <p>টোটাল Level - 1</p>
                                    <p>টোটাল MCQ - {state.result.total_mcq}</p>

                                    <p>টোটাল সঠিক - {state.result.total_correct}</p>
                                    <p>টোটাল ভুল - {state.result.total_wrong}</p>
                                    <p>উত্তর দেওয়া হয়নি - {state.result.total_unanswered}</p>
                                    <p>টোটাল Mark - {state.result.total_mark}</p>
                                    <p>টোটাল Time - {state.result.total_time.toFixed(2)} secs</p>
                                </div>
                                <h4 className="mb-2">Follow Us On:</h4>

                                <div className="social mb-5">
                                    <a href="https://www.facebook.com/profile.php?id=100088235866815" target={"_blank"} rel="noreferrer">
                                        <i className="fab fa-facebook-square" style={{ fontSize: "30px", color: "#1877F2", marginRight: "10px" }} ></i>
                                    </a>
                                    <a href="https://twitter.com/Quizwin_xyz" target={"_blank"} rel="noreferrer" >
                                        <i className="fab fa-twitter" style={{ fontSize: "30px", color: "#1D9BF0", marginRight: "10px" }}></i>
                                    </a>
                                    <a href="https://www.instagram.com/quizwin.xyz/" target={"_blank"} rel="noreferrer">
                                        <i className="fab fa-instagram" style={{ fontSize: "30px", color: "#FF0000", marginRight: "10px" }}></i>
                                    </a>
                                    <a href="https://www.linkedin.com/in/quizwin-xyz-b54152266/" target="_blank" rel="noreferrer">
                                        <i className="fab fa-linkedin" style={{ fontSize: "30px", color: "#0A66C2", marginRight: "10px" }}></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MyResult