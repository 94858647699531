import axios from "axios";
import React, { useEffect, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import iso1 from '../../assets/images/iso1.png';
import iso2 from '../../assets/images/iso2.png';
import iso3 from '../../assets/images/iso3.jpg';
import "./index.css";

// const Home = ({t, login, setlogin, setgolobalKeyword,searchKeyword,setSearchKeyword}) => {
const Home = ({ isAuthenticated, userInfo }) => {
  const [sliders, setSliders] = useState([]);
  const [about, setAbout] = useState(false);


  useEffect(() => {
    // fetch("https://quizbuz-backend.techknowgram.net/api/react/events")
    //     .then((response) => response.json())
    //     .then((data) => setEvents(data));

    const url = "https://quizbuz-backend.techknowgram.net/api/react/sliders";

    axios
      .get(url)
      .then(function (response) {
        console.log(response);
        const { data } = response;
        setSliders(data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  console.log("sliders", sliders);
  const navigate = useNavigate();

  const goToEvents = (e) => {
    e.preventDefault();
    navigate("/events");
  };

  const gotoBuy = (e) => {
    e.preventDefault();
    navigate("/pointbuy");
  }

  const goToRule = (e) => {
    e.preventDefault();
    navigate("/rule");
  };

  const goToFaq = (e) => {
    e.preventDefault();
    navigate("/faq");
  };

  const goToabout = (e) => {
    setAbout(!about);
  }

  const goToPrizes = (e) => {
    e.preventDefault();
    navigate("/prize");
  };


  const goToRegister = (e) => {
    e.preventDefault();
    navigate("/user-register");
  };

  const goToLogin = (e) => {
    e.preventDefault();
    navigate("/user-login");
  };

  const goToProfile = (e) => {
    e.preventDefault();
    navigate("/profile");
  };

  const goToLogout = (e) => {
    e.preventDefault();
    navigate("/logout");
  };

  const goToResult = (e) => {
    e.preventDefault();
    navigate("/result");
  };

  let authUrls = (
    <>
      <a className="regOnlyOnMobile" onClick={goToRegister} href="">
        রেজিস্ট্রেশন
      </a>
      <a className="regOnlyOnMobile" onClick={goToLogin} href="">
        লগইন
      </a>
    </>
  );

  if (isAuthenticated) {
    authUrls = (
      <>
        <a className="regOnlyOnMobile" onClick={goToProfile} href="">
          প্রোফাইল
        </a>
        <a className="regOnlyOnMobile" onClick={goToLogout} href="">
          লগআউট
        </a>
      </>
    );
  }

  return (
    <>
      <section style={{marginTop: "138px"}} id="banner">
        <div class="container">
          <div class="row d_flex">
            <div class="col-lg-6">
              <div class="left">
               
                <h1>কুইজ খেলুন, পুরস্কার জিতুন !</h1>
               
                <a onClick={goToEvents} href="">
                  কুইজে অংশগ্রহন করুন
                </a>

                <div className="d-flex d-md-none justify-content-between align-items-center">
                  {authUrls}
                </div>

               
                <h4 class="dontShowOnMobile">
                  আপনার পয়েন্ট <span> {isAuthenticated ? userInfo.balance: 0}</span>{" "}
                </h4>
                <h5 className="dontShowOnMobile">
                  <a onClick={gotoBuy} href="" className="d_flex">
                    পয়েন্ট কিনুন
                    <img src={require("../../images/arrow-right.png")} alt="" />
                  </a>
                </h5>
               
              </div>
            </div>
            <div class="col-lg-6">
              <div class="banner_content">
                <div class="img">
                <img
                    src={require("../../images/homepage-photo.jpg")}
                    alt=""
                  />

                  {/* @endif */}
                  {/* <div class="overlay_img">
                    <img
                      src="http://quizwin.xyz/admin/frontend/images/idea.gif"
                      alt=""
                    />
                  </div> */}
                </div>
                <div class="text">
                  {/* @if($homepage->image_footer_content)
                           
                        @else
                           
                        @endif */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container showOnlyOnMobile">
          <div class="d-flex my-3 justify-content-center w-100">
            <div class="w-50 mx-2 p-1  border border-primary rounded">
             

              <a href="" class="d_flex text-center">
                আপনার পয়েন্ট&nbsp;{isAuthenticated ? userInfo.balance: 0}
              </a>
            </div>
            <div class="w-50 mx-2 p-1  border border-primary rounded">
              {" "}
              <a onClick={gotoBuy} class="d_flex text-center">
               
                পয়েন্ট সংগ্রহ করুন
              
              </a>
            </div>
          </div>
          <div class="d-flex justify-content-center w-100">
          <div class="w-50 mx-2 p-1  border border-primary rounded">
              <a onClick={goToResult} class="d_flex text-center">
              ফলাফল{" "}
              </a>{" "}
            </div>
            <div class="w-50 mx-2 p-1  border border-primary rounded">
              <a onClick={goToPrizes} class="d_flex text-center" href="javascript:;">
                পুরস্কার লিস্ট{" "}
              </a>{" "}
            </div>
          </div>
        </div>
      </section>

      <section id="banner2">
        {/* <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              {sliders.length ? (
                <CarouselFade sliders={sliders} />
              ) : (
                <h1>Sliders Loading...</h1>
              )}
            </div>
          </div>
        </div> */}

        <div class="container-fluid p-0 footerOnMobile">
          <div class="col-lg-3 col-md-3">
            <div class="footer_content">
             
              <h3 style={{ marginLeft: "1em", marginTop: "2em" }}>Pages</h3>
              {/* @endif */}
              <div
                class="list-none"
                style={{ marginLeft: "2em", marginBottom: "2em" }}
              >
              
                <h5>
                  <a onClick={goToRule} href="javascript:;">
                    নিয়মাবলি
                  </a>
                </h5>
               
                <h5>
                  <a onClick={goToEvents} href="">
                    সকল কুইজ
                  </a>
                </h5>
              
                <h5>
                  <a onClick={goToFaq} href="">
                    FAQ
                  </a>
                </h5>
                {/* @endif */}
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3" style={{ marginLeft: "2em" }}>
           

            <h4 class="mb-2">Follow Us On:</h4>

            <div class="social mb-5">
              <a href="https://www.facebook.com/profile.php?id=100088235866815">
                <i
                  class="fab fa-facebook-square"
                  style={{
                    fontSize: "30px",
                    color: "#1877F2",
                    marginRight: "10px",
                  }}
                ></i>
              </a>
              <a href="#">
                <i
                  class="fab fa-twitter"
                  style={{
                    fontSize: "30px",
                    color: "#1D9BF0",
                    marginRight: "10px",
                  }}
                ></i>
              </a>
              <a href="#">
                <i
                  class="fab fa-youtube"
                  style={{
                    fontSize: "30px",
                    color: "#FF0000",
                    marginRight: "10px",
                  }}
                ></i>
              </a>
              <a href="#">
                <i
                  class="fab fa-linkedin"
                  style={{
                    fontSize: "30px",
                    color: "#0A66C2",
                    marginRight: "10px",
                  }}
                ></i>
              </a>
            </div>

             {/* <a
              className="btn btn-dark"
              href="http://quizwin.xyz/admin/uploads/quizbuz.apk"
            >
              <i className="fa fa-download mx-2" aria-hidden="true"></i>
              Download App
            </a> */}
          </div>
          <div className="col-lg-3 col-md-3" style={{ marginLeft: "2em", marginBottom: "3em" }}>
            <h3 className="about">About us</h3>
            <h5 >QuizWin is a knowledge based gaming platform of TechKnowGram Limited. TechKnowGram Limited is a leading technology company based in Bangladesh that specializes in providing web-based customized solutions in various areas, including ERP, AI, Data Analytics, Robotics, Embedded Solutions with Beacon, AR, VR Applications, Gaming, and Animation. {!about && ("...")}{about && (<h5>The company is globally recognized for its expertise in the Japan ICT market, where it has been operating for over 20 years. Additionally, TechKnowGram Limited also has a strong presence in the USA and Europe markets. With over 25 years of combined professional experience, the company's team of experts is dedicated to delivering innovative solutions to its clients. TechKnowGram Limited is proud to be an ISO 9001: 2015, ISO 14001: 2015, and ISO/IEC 27001: 2013 certified company and a member of several reputable organizations, including Bangladesh Association of Software and Information Services BASIS, Bangladesh and Japan Chamber of Commerce & Industries JBCCI, Bangladesh Computer Samity BCS, Bangladesh Association of Call Centers and Outsourcing BACCO, Ecommerce Association of Bangladesh e-CAB, CTO Forum of Bangladesh, and SheTrades Commonwealth Program by International Trade Centre ITC.</h5>)}  </h5><h5 onClick={goToabout} style={{ color: "blue", cursor: "pointer" }}>{about ? "Read less" : "Read more"}</h5>
            <div className="social d-flex align-items-center">
              <img height={50} width={50} src={iso1} alt="" />
              <img className="mx-3 mt-2" height={55} width={70} src={iso2} alt="" />
              <img height={50} width={50} src={iso3} alt="" />
            </div>
          </div>
        </div>
        {/* <div className="row footerOnMobile">
          <div id="footer-bottom" className="clr no-footer-nav p-3">
            <div id="footer-bottom-inner" className="container clr">
              <div id="copyright" className="clr" role="contentinfo">
                <div className="d-flex justify-content-center align-items-center">
                  © All Rights Reserved &nbsp;& Powered By TechKnowGram Limited
                  <a href="https://www.techknowgram.com" target="_Blank">
                    {" "}
                    <img
                      src={require("../../images/tkgl_logo.png")}
                      width="100"
                      height="35"
                      className="lazyloaded"
                      data-ll-status="loaded"
                    />
                  </a>
                </div>

              </div>


            </div>

          </div>
        </div> */}
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    userInfo: state.auth.user
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
