import axios from "axios";
import CryptoJS from "crypto-js";
import React, { useEffect, useRef, useState } from "react";
import { FallingLines } from "react-loader-spinner";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import logo from '../../assets/images/logo.png';
import { authHeader } from "../../utils";

const EventDetails = () => {
  const [event, setEvent] = useState({});
  const [alreadyPlayed, setAlreadyPlayed] = useState(false);
  const { eventId } = useParams();
  const { state } = useLocation();

  const sectionEvDetailsRef = useRef(null);

  console.log("IsUpcoming", state.IsUpcoming);

  const navigate = useNavigate();

  useEffect(() => {
    if (!state.IsUpcoming) {
      const url = "https://quizbuz-backend.techknowgram.net/api/react/events";

      axios
        .get(url + "/" + eventId, { headers: authHeader() })
        .then(function (response) {
          const { data } = response;

          let encrypted = data.encrypted;
          let key = "P53Eryk3cPcED2pKV2FBkvzOIuFty63djJKQSEa6bEs";

          encrypted = JSON.parse(atob(encrypted));
          //console.log(encrypted);

          const iv = CryptoJS.enc.Base64.parse(encrypted.iv);
          const value = encrypted.value;

          key = CryptoJS.enc.Base64.parse(key);

          let decrypted = CryptoJS.AES.decrypt(value, key, { iv: iv }).toString(
            CryptoJS.enc.Utf8
          );

          const myData = JSON.parse(decrypted);
          if (myData.event_status != "upcoming" && !myData.event_mark_setup) {
            setAlreadyPlayed(true);
          }
          setEvent(myData);
        })
        .catch(function (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message &&
            error.response.data.message == "Unauthenticated."
          ) {
            navigate("/logout");
            return;
          }
        });
    }
  }, []);

  const startEvent = (e) => {
    e.preventDefault();
    navigate("/eventPlay/" + eventId);
  };

  useEffect(() => {
    sectionEvDetailsRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <>
      <section id="banner" class="Banner_others section_gaps faq">
        <div class="container">
          <div class="row d_flex">
            <div class="col-lg-6 m-auto">
              <div class="left">
                <h1>কুইজে অংশগ্রহন করুন</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section style={{ height: "50px" }} ref={sectionEvDetailsRef}></section>
      <section id="attend_quiz" class="section_gaps">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6 col-12 m-auto">
              <div class="attend_quiz_item">
                {alreadyPlayed ? (
                  <span class="" style={{ color: "red" }}>
                    You have already played this event maximum times
                  </span>
                ) : (
                  <>
                    {state.IsUpcoming || event.event_status == "upcoming" ? (
                      <span class="" style={{ color: "red" }}>
                        Event not started yet
                      </span>
                    ) : (
                      <>
                        {" "}
                        <div class="img">
                          <img
                            class="img-fluid w-sm-50 w-md-100"
                            src={logo}
                            alt=""
                          />
                        </div>
                        <div class="text">
                          {event.event_name && event.event_name.length ? (
                            <>
                              <h3> ডেইলি চ্যালেঞ্জ</h3>
                              <h4>
                                প্রতিদিন ডেইলি চ্যালেঞ্জ খেলুন, আর লিডারবোর্ড এর
                                শীর্ষে থাকুন
                              </h4>

                              <p>ইভেন্ট - {event.event_name || ""}</p>
                              <p>
                                টোটাল MCQ -{" "}
                                {event.event_mark_setup.total_mcq || 0}
                              </p>
                              <p>
                                Per MCQ Mark -{" "}
                                {event.event_mark_setup.per_mcq_mark || 0}
                              </p>
                              <p>
                                Per MCQ Time -{" "}
                                {event.event_mark_setup.per_mcq_time_duration ||
                                  0}{" "}
                                sec
                              </p>
                              <p>
                                টোটাল লেভেল -{" "}
                                {event.event_mark_setup.total_level || 0}
                              </p>
                              <p>
                                প্রতি লেভেলে প্রশ্ন -{" "}
                                {event.event_mark_setup.question_per_level || 0}
                              </p>

                              <p
                                style={{
                                  background: "#c1e1c1",
                                  padding: "24px",
                                  margin: "16px 0px",
                                }}
                              >
                                ডেইলি চ্যালেঞ্জ শুরু করতে{" "}
                                <span style={{ color: "red" }}>
                                  {event.coin || 0}
                                </span>{" "}
                                পয়েন্ট প্রয়োজন
                              </p>

                              <span class="insufficient_balance"></span>

                              <div class="start" data-id={event.id}>
                                <a onClick={startEvent} href="#">
                                  {" "}
                                  শুরু করুন{" "}
                                </a>
                              </div>
                            </>
                          ) : (
                            <FallingLines
                              color="#4fa94d"
                              width="100"
                              visible={true}
                              ariaLabel="falling-lines-loading"
                            />
                          )}
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EventDetails;
