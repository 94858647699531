import { memo, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { upperCaseFirst } from "../../../lib/string";
import { greetingMessage } from "../../../lib/time";
import styles from "./index.module.css";

function WelcomModal({ userInfo, show, handleClose, weatherData }) {
  const [date, setDate] = useState(new Date().toLocaleString()); //State for time

  const updateDate = () => setDate(new Date().toLocaleString()); //Date updater function

  weatherData = JSON.parse(weatherData);
  if (JSON.stringify(weatherData) !== "{}") {
    var { temp, feels_like } = weatherData.main;
    var { speed, deg } = weatherData.wind;
    var { description, icon } = weatherData.weather[0];

    var iconLink = `https://openweathermap.org/img/wn/${icon}@4x.png`;
  }

  /**
   * For updateing the time in the modal
   */
  useEffect(() => {
    const tick = setInterval(updateDate, 1000);

    return () => clearInterval(tick);
  }, []);

  /**
   * The welcome modal
   */
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header
          className={`d-flex justify-content-center ${styles.modalHeader}`} //profile_photo_path
        >
          <Modal.Title className="text-white">
            <h3>Welcome {userInfo.name || userInfo.username}</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center">
          <div className="w-70">
            <h4 className="text-center">
              {greetingMessage()}&nbsp;&nbsp;
              {userInfo.full_user_photo_path ? (
                <img className="rounded-circle" height={"70"} width={"70"} src={userInfo.full_user_photo_path} alt="" />
              ) : (
                <span>
                  <i className="fa fa-user" style={{ fontSize: "30px" }}></i>
                </span>
              )}
            </h4>
            <br />
            {weatherData.name && (
              <>
                <h4 className="text-center">{weatherData.name}</h4>
                <br />
                <p>Todays date: {date}</p>
                <p>Temprature: {(temp - 273).toFixed(2)}(C)</p>
                <p>Feels like: {(feels_like - 273).toFixed(2)}(C)</p>
                <p>Windspeed: {speed}</p>
                <p>Wind direction: {deg}</p>
                <br />
                <h5 className="text-center">{upperCaseFirst(description)}</h5>
                <p className="text-center">
                  <img src={iconLink} alt="" />
                </p>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default memo(WelcomModal);
