import axios from "axios";
import React, { useEffect, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../images/logo.png";
import { authOperations } from "../../state/ducs/auth";
import { authHeader } from "../../utils";

import { connect } from "react-redux";

import "./index.css";

const Varify = ({ checkUser }) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  console.log("state", state);
  const [varify, setVarify] = useState([]);

  const [number1, setNumber1] = useState("");
  const [number2, setNumber2] = useState("");
  const [number3, setNumber3] = useState("");
  const [number4, setNumber4] = useState("");

  useEffect(() => {}, []);

  console.log("varify", varify);

  const submitOtps = (e) => {
    e.preventDefault();

    const url =
      "https://quizbuz-backend.techknowgram.net/api/react/coins/confirmPurchase";

    const number = number1 + "" + number2 + "" + number3 + "" + number4;

    const postObj = {
      service_name: state.service_name,
      otp: +number,
      message_id: state.message_id,
    };

    axios
      .post(url, postObj, { headers: authHeader() })
      .then(function (response) {
        console.log(response);
        const { data } = response;
        if (data.success) {
          checkUser();
          navigate("/paymentSuccess");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <section id="login">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-8 col-sm-8 m-auto">
              <form>
                <div class="login_coltent">
                  <div class="img">
                    <img src={logo} alt="" />
                  </div>
                  <h2>Varification Code</h2>

                  <div class="custome_input verification form-wrap">
                    <input
                      type="text"
                      placeholder=""
                      name="number_1"
                      max="1"
                      maxlength="1"
                      required
                      value={number1}
                      onChange={(e) => setNumber1(e.target.value)}
                    />
                    <input
                      type="text"
                      placeholder=""
                      name="number_2"
                      max="1"
                      maxlength="1"
                      required
                      value={number2}
                      onChange={(e) => setNumber2(e.target.value)}
                    />
                    <input
                      type="text"
                      placeholder=""
                      name="number_3"
                      max="1"
                      maxlength="1"
                      required
                      value={number3}
                      onChange={(e) => setNumber3(e.target.value)}
                    />
                    <input
                      type="text"
                      placeholder=""
                      name="number_4"
                      max="1"
                      maxlength="1"
                      required
                      value={number4}
                      onChange={(e) => setNumber4(e.target.value)}
                    />

                    <input
                      type="hidden"
                      name="message_id"
                      value="{{ $message_id }}"
                    />
                    <input
                      type="hidden"
                      name="package_name"
                      value="{{ $package }}"
                    />
                  </div>
                  <div class="custome_input">
                    <a href="">
                      <button type="button" class="resend">
                        {" "}
                        RESEND CODE{" "}
                      </button>
                    </a>

                    <button onClick={submitOtps} type="submit">
                      {" "}
                      CONFIRM{" "}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {
  checkUser: authOperations.authUserCheck,
};

export default connect(mapStateToProps, mapDispatchToProps)(Varify);
